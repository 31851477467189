  import React, {
    useEffect,
    useState,
    lazy,
    Component,
    Suspense,
    useContext,
  } from "react";
  import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
  } from "react-router-dom";

  import { createTheme, ThemeProvider } from "@material-ui/core/styles";

  import SignUp from "./components/SignUp";
  import SignInMagicLink from "./components/SignInMagicLink";
  import MagicLinkWait from "./components/MagicLinkWait";

  import Admin from "./components/Admin";

  import ForgotPassword from "./components/ForgotPassword";
  import PrivacyPolicy from "./components/PrivacyPolicy.js";

  import QuadraticFunction from "!babel-loader!@mdx-js/loader!./pages_posts/posts/QuadraticFunction.mdx";
  import InteractiveMathematics from "!babel-loader!@mdx-js/loader!./pages_posts/posts/InteractiveMathematics.mdx";
  import InteractiveML from "!babel-loader!@mdx-js/loader!./pages_posts/posts/InteractiveML.mdx";

  import AIConsulting from "!babel-loader!@mdx-js/loader!./pages_posts/posts/AIConsulting.mdx";
  import TennisAI from "!babel-loader!@mdx-js/loader!./pages_posts/posts/TennisAI.mdx";
  import AIFinance from "!babel-loader!@mdx-js/loader!./pages_posts/posts/AIFinance.mdx";
  import Activations from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Activations.mdx";
  import BackPropagation_Buckets from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/BackPropagation_Buckets.mdx";
  import BackPropagation from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/BackPropagation.mdx";
  import DotProduct from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/DotProduct.mdx";
  import MultivariateGaussian from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/MultivariateGaussian.mdx";
  import VAE_QUIZ from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE_QUIZ.mdx";
  import OptionsD3 from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/OptionsD3.mdx";
  import VAE_FlipBook from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE_FlipBook.mdx";

  import GAN_QUIZ from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/GAN_QUIZ.mdx";
  import Embeddings from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Embeddings.mdx";

  import KL_Divergence from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/KL_Divergence.mdx";

  import Gradient from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Gradient.mdx";
  import LogisticRegression from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LogisticRegression.mdx";
  import PartialDerivatives from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/PartialDerivatives.mdx";
  import Perceptron from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Perceptron.mdx";
  import SingleLayerPerceptron from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/SingleLayerPerceptron.mdx";
  import ActivationFunctions from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/ActivationFunctions.mdx";
  import DActivationFunctions from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/DActivationFunctions.mdx";

  import LogisticRegressionLearnPath from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LogisticRegressionLearnPath.mdx";

  import Transformers from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Transformers.mdx";
  import VAE from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE.mdx";
  import GAN from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/GAN.mdx";
  import LLM from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LLM.mdx";
  import RAG from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/RAG.mdx";
  import YieldCurves from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/YieldCurves.mdx";
  import LandingPageStocks from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LandingPageStocks.mdx";

  import IntroPuts from "!babel-loader!@mdx-js/loader!./pages_posts/posts/IntroPuts.mdx";

  import Quantization from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Quantization.mdx";
  import Granville from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Granville.mdx";
  import GenusWorkshops from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/GenusWorkshops.mdx";



  import AskBot from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Vanmates/AskBotMDX.mdx";
  import PaymentContext from "./components/paymentContext";
  import StripeBuyButton from "./components/StripeBuyButton";
  import GoogleSignInButton from "./components/GoogleSignInButton";
  import LandingPage from "./components/LandingPage"; // Assuming the LandingPage is in the same directory
  import PrivateRoute from "./components/PrivateRoute"; // Import the new component
  import PaymentPage from "./components/PaymentPage"; // Assuming the LandingPage is in the same directory
  import AccessPage from "./components/AccessPage"; // Assuming the LandingPage is in the same directory
  import Anatomy from "./components/Topics/anatomy/AnatomyApp"; // Assuming the LandingPage is in the same directory
  import DL from "./components/Topics/dl/DLApp"; // Assuming the LandingPage is in the same directory
 
  import AI from "./components/Topics/ai/AIApp.js"; // Assuming the LandingPage is in the same directory
  import Calculus from "./components/Topics/calculus/CalculusApp.js";
  import PerceptronApp from "./components/Topics/perceptron_app/PerceptronApp"; // Assuming the LandingPage is in the same directory
  import * as SpecialistMathRoutes from './components/SpecialistMath';
  import * as OptionsRoutes from './components/options';


  const darkTheme = createTheme({
    palette: {
      mode: "light",
    },
    typography: {
      subtitle1: {
        fontSize: 12,
      },
      body1: {
        fontWeight: 500,
      },
    },
  });

  export const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      start: 5,
      end: 24,
    },
  };

  const appStyles = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  };

  let blogs_chain;
  export default function MathApp() {
    const location = useLocation();

    const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
    const { paymentFlag, user } = useContext(PaymentContext);


        // Combine all routes into one object
    const allRoutes = {
      ...SpecialistMathRoutes,
      ...OptionsRoutes,
      // ... include other subjects similarly
    };

    // Then map over the routes to create Route components
    const routeComponents = Object.keys(allRoutes).map((key, index) => {
      const Component = allRoutes[key];
      const path = `/${key.replace(/_/g, '-').toLowerCase()}`;
      return <Route key={index} path={path} component={Component} />;
    });


    const exemptedPaths = ["/", "/int_ml", "/markets", "/signin", "/payment", "/yield_curves", "/privacy_policy","/ai_finance","/dl","/ai","/calculus"];

    if (!paymentFlag && !exemptedPaths.includes(location.pathname)) {
      return (
        <div style={{ 
          width: '400px', 
          margin: '0 auto', 
          marginTop: '50px', 
          textAlign: 'center', 
          color: '#E0E0E0', 
          fontFamily: 'Roboto, Arial, sans-serif' // Using Roboto, with Arial as fallback.
        }}>
          <div style={{ marginBottom: '20px' }}>
            <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
              Step 1: Sign in with Google
            </h2>
            <GoogleSignInButton />
          </div>
          <br/>
          <br/>
          <div>
            <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
              Step 2: Complete your subscription
            </h2>
            <br/>
          <br/>
            <StripeBuyButton />
          </div>
        </div>
      );
}



  // if (!user && !exemptedPaths.includes(location.pathname)) {
  //   return <GoogleSignInButton />;
  // }

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
         {routeComponents}
          <Route exact path="/">
            <LandingPage />
          </Route>

          <PrivateRoute path="/taxes">
            <h2>Taxes</h2>
          </PrivateRoute>

          <Route path="/loans">
            <h2>Loans</h2>
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/signin">
            <SignInMagicLink />
          </Route>

          <Route path="/wait">
            <MagicLinkWait />
          </Route>

          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>

          <Route path="/int_math">
            <InteractiveMathematics />
          </Route>

          <Route path="/int_ml">
            <InteractiveML />
          </Route>

          <Route path="/google">
            <GoogleSignInButton />
          </Route>

          <Route path="/payment">
            <PaymentPage />
          </Route>

          <Route path="/ai_consulting">
            <AIConsulting />
          </Route>

          <Route path="/access" exact>
            <AccessPage nextRoute="/tennis_ai" />
          </Route>

          <Route path="/Activations">
            <Activations />
          </Route>

          <Route path="/lrpath">
            <LogisticRegressionLearnPath />
          </Route>

          <Route path="/BackPropagation_Buckets">
            <BackPropagation_Buckets />
          </Route>
          <Route path="/BackPropagation">
            <BackPropagation />
          </Route>
          <Route path="/vector_arithmetic">
            <DotProduct />
          </Route>
          <Route path="/vae_quiz">
            <VAE_QUIZ />
          </Route>

          <Route path="/yield_curves">
            <YieldCurves />
          </Route>

          <Route path="/markets">
            <LandingPageStocks />
          </Route>

          <Route path="/intro_puts">
            <IntroPuts />
          </Route>

          <PrivateRoute path="/optionsd3">
            <OptionsD3 />
          </PrivateRoute>


            <PrivateRoute path="/anatomy">
              <Anatomy />
            </PrivateRoute>

          
          <PrivateRoute path="/vae_flip">
            <VAE_FlipBook />
          </PrivateRoute>

          <PrivateRoute path="/access">
            <AccessPage />
          </PrivateRoute>

          <PrivateRoute path="/gan_quiz">
            <GAN_QUIZ />
          </PrivateRoute>

          <PrivateRoute path="/rag">
            <RAG />
          </PrivateRoute>

          <PrivateRoute path="/quantization">
            <Quantization />
          </PrivateRoute>


          <Route path="/dl">
            <DL />
          </Route>


          <Route path="/ai">
            <AI />
          </Route>


          <Route path="/calculus">
            <Calculus />
          </Route>


          <PrivateRoute path="/perceptron_app">
            <PerceptronApp />
          </PrivateRoute>

          <PrivateRoute path="/granville">
            <Granville />
          </PrivateRoute>


          <PrivateRoute path="/genus_workshops">
            <GenusWorkshops />
          </PrivateRoute>



          <PrivateRoute path="/askbot">
            <AskBot />
          </PrivateRoute>

          <Route path="/tennis_ai">
            <TennisAI />
          </Route>


          <Route path="/ai_finance">
            <AIFinance />
          </Route>

          <PrivateRoute path="/embeddings">
            <Embeddings />
          </PrivateRoute>

          <PrivateRoute path="/kl_divergence">
            <KL_Divergence />
          </PrivateRoute>



          <PrivateRoute path="/multi_gaussian">
            <MultivariateGaussian />
          </PrivateRoute>

          <PrivateRoute path="/Gradient">
            <Gradient />
          </PrivateRoute>

          <PrivateRoute path="/LogisticRegression">
            <LogisticRegression />
          </PrivateRoute>

          <PrivateRoute path="/PartialDerivatives">
            <PartialDerivatives />
          </PrivateRoute>

          <PrivateRoute path="/Perceptron">
            <Perceptron />
          </PrivateRoute>

          <PrivateRoute path="/single_layer_perceptron">
            <SingleLayerPerceptron />
          </PrivateRoute>

          <PrivateRoute path="/activation_functions">
            <ActivationFunctions />
          </PrivateRoute>

          <PrivateRoute path="/d_activation_functions">
            <DActivationFunctions />
          </PrivateRoute>

          <PrivateRoute path="/gan">
            <GAN />
          </PrivateRoute>

          <PrivateRoute path="/llm">
            <LLM />
          </PrivateRoute>

         <PrivateRoute path="/transformers">
            <Transformers />
          </PrivateRoute> 

          {/* <Route path="/transformers">
            <Transformers />
          </Route> */}



          <PrivateRoute path="/vae">
            <VAE />
          </PrivateRoute>

          <Route path="/privacy_policy">
            <PrivacyPolicy />
          </Route>

          <Route path="/__/auth/">
            <Admin />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
